import React, { useState } from 'react';
import './index.scss'
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { API } from '../../Api/API';
import { setItem, setSessionStorage } from '../../Utils/util';
toast.configure();

const Login = (props) => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [emailErr, setEmailErr] = useState(false);
    const [passwordErr, setPasswordErr] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [validateEmailErr, setValidateEmailErr] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);

    const handleEmailInput = (value) => {
        if (value.length <= 0) {
            setEmailErr(true);
        } else {
            setEmailErr(false);
            if (value.match(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(?:\.\w{2,3})+$/)) {
                setValidateEmailErr(false);
            } else {
                setValidateEmailErr(true);
            };
        };
        setEmail(value);
    };

    const handlePasswordInput = (value) => {
        if (value.length <= 0) {
            setPasswordErr(true);
        } else {
            setPasswordErr(false);
        }
        setPassword(value);
    };

    const showHidePassword = () => {
        setShowPassword(!showPassword);
    };

    const doLogin = (e) => {
        e.preventDefault();
        if (!email || emailErr || validateEmailErr) {
            if (!email || emailErr) {
                toast.error('Email is required');
            } else {
                toast.error('Invalid email');
            };
        } else if (!password || passwordErr) {
            if (!password) {
                toast.error('Password is required');
            };
        } else {
            loginApiCall();
        };
    };

    const handleRememberMe = (e) => {
        setRememberMe(e);
    };

    const loginApiCall = async () => {
        let result = await API.login(email, password);
        if (result && result.success) {
            let { data } = result;
            toast.success(data.message);
            setItem('rememberMe', rememberMe);
            if (rememberMe) {
                setItem('accessToken', data.token);
                let userInforamtion = JSON.stringify(data.userInfo);
                setItem('userInfo', userInforamtion);
            } else {
                setSessionStorage('accessToken', data.token);
                let userInforamtion = JSON.stringify(data.userInfo);
                setSessionStorage('userInfo', userInforamtion);
            }
            setEmail('');
            setPassword('');
            props.getData();
            // if (history.location.pathname === '/sign-up') {
            history.push('/home')
            // } else {
            //     history.goBack();
            // }
        } else {
            toast.error(result.message);
        };
    };

    const navigateToSignUp = () => {
        history.push('/sign-up');
    };

    // const navigateToForgotPassword = () => {
    //     history.push('/forgot-password')
    // }

    return (
        <div className="container">
            <Helmet>
                <title>Login | ELL Stores </title>
            </Helmet>
            <div className="row mt-5">
                <div className="col-lg-6 col-md-10 ms-auto me-auto mb-3">
                    <div className="login-form text-center box-shadow white-bg pt-md-5 pt-2 pb-5">
                        <h2 className="title mb-4">
                            <span>Login</span>
                        </h2>
                        <form id="contact-form" className="px-md-5 px-4">
                            <div className="form-group">
                                <div className="position-relative">
                                    <i className="fas fa-envelope"></i>
                                    <input id="form_name" type="text" name="email" className="form-control" placeholder="Email"
                                        value={email}
                                        onBlur={(e) => handleEmailInput(e.target.value)}
                                        onChange={(e) => handleEmailInput(e.target.value)}
                                    />
                                </div>
                                {
                                    emailErr ?
                                        <div className="text-end text-danger p-1 err_txt">Email is required</div>
                                        : null
                                }
                                {
                                    validateEmailErr && !emailErr ?
                                        <div className="text-end text-danger p-1 err_txt">Invalid email</div>
                                        : null
                                }
                            </div>
                            <div className="form-group">
                                <div className="position-relative">
                                    {
                                        showPassword ?
                                            <i onClick={() => { showHidePassword() }} className="fa fa-unlock-alt"></i>
                                            :
                                            <i onClick={() => { showHidePassword() }} className="fas fa-lock"></i>
                                    }
                                    <input id="form_password" type={showPassword ? "text" : "password"} name="password" className="form-control" placeholder="Password"
                                        value={password}
                                        onBlur={(e) => handlePasswordInput(e.target.value)}
                                        onChange={(e) => handlePasswordInput(e.target.value)}
                                    />
                                </div>
                                {
                                    passwordErr ?
                                        <div className="text-end text-danger p-1 err_txt">Password is required</div>
                                        : null
                                }
                            </div>
                            <div className="form-group mt-md-4 mb-md-5">
                                <div className="remember_div">
                                    <div className="">
                                        <input
                                            type="checkbox"
                                            id="customCheck1"
                                            value={rememberMe}
                                            onChange={(e) => { handleRememberMe(e.target.value) }}
                                        />
                                        <label className="custom-control-label rember_me_txt" for="customCheck1">Remember me</label>
                                    </div>
                                    <div onClick={() => { }} //navigateToForgotPassword()
                                        className="forgot_password">Forgot password?</div>
                                </div>
                            </div>
                            <button onClick={(e) => { doLogin(e) }} style={{ width: '100%' }} className="btn btn-theme btn-block">
                                <span>Signin</span>
                            </button>
                            <div>
                                <p className="mb-0 mt-3 text-black">Don't you have an account ?
                                    <span onClick={() => navigateToSignUp()} className="sign_up_txt">{' Sign it Up!'}</span>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
