import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import background_img from '../Assets/Images/leather-bg.jpg';
import { API } from '../Api/API';
import { clearLocalStorage, getItem, getSessionStorage } from '../Utils/util';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const footer_root = document.getElementById('footer');

const Footer = (props) => {
    const { loginAccessToken, getData } = props;
    const history = useHistory();
    const [accessToken, setAccessToken] = useState(loginAccessToken);
    const [userInfo, setUserInfo] = useState('');
    const [email, setEmail] = useState('');

    useEffect(() => {
        let getToken = getItem('accessToken') || getSessionStorage('accessToken');
        let getUserInfo = getItem('userInfo') || getSessionStorage('userInfo');
        setAccessToken(getToken);
        setUserInfo(getUserInfo);
    }, [loginAccessToken, accessToken]);

    const subscribeToEll = async () => {
        if (!email) {
            toast.error('Please enter email');
            return;
        }
        if (email.match(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(?:\.\w{2,3})+$/)) {
            if (accessToken) {
                let userData = JSON.parse(userInfo);
                if (email === userData.email_id) {
                    let result = await API.subscribe(email, accessToken);
                    if (result.message === "Invalid token") {
                        clearLocalStorage();
                        getData();
                        history.push('./login');
                    }
                    if (result && result.success) {
                        let { data } = result;
                        if (data) {
                            setEmail('');
                            toast.success(data.message);
                        } else {
                            toast.error('Something went wrong, please try again');
                        }
                    }
                } else {
                    toast.error('Pease enter loggedin email id');
                }
            } else {
                toast.error('Please login');
            }
        } else {
            toast.error('Invalid email');
        }
    };

    const render_footerComponent = (props) => {
        return (
            <footer style={{ backgroundImage: `url(${background_img})` }}>
                <div className="container col-md-12 pt-2 pb-2">
                    <hr style={{ color: 'white' }} />
                    <div className="d-flex align-items-center flex-wrap">
                        <p className="me-3 mb-0 text-white f-14">SUBSCRIBE TO OUR NEWSETTER</p>
                        <div className="d-flex mt-3 mb-3 col-12 col-md-6">
                            <input className="p-2 border_outline_none f-14" type="email" value={email} onChange={(e) => setEmail(e.target.value)} style={{ width: '45%' }} placeholder="Email address" />
                            <button onClick={() => { subscribeToEll() }} className="f-14 subscribe_btn border_outline_none p-2">SUBSCRIBE</button>
                        </div>
                    </div>

                    <div className='col-md-12 row align-items-center'>
                        <p className="f-14 col-md-6 mb-0 text-white">
                            COPYRIGHT © {new Date().getFullYear()}
                            <span className="pe_cursor text-white text-decoration-none terms_hovered"
                                rel="nofollow"
                                onClick={() => { history.push('./home') }}> ELL STORES </span>|
                            <span className="pe_cursor text-white text-decoration-none terms_hovered"
                                rel="nofollow"
                                onClick={() => { history.push('./terms-and-conditions') }}> TERMS </span>|
                            <span className="pe_cursor text-white text-decoration-none terms_hovered"
                                rel="nofollow"
                                onClick={() => { history.push('./privacy-policy') }}> PRIVACY POLICY </span>|
                            <span className="pe_cursor text-white text-decoration-none terms_hovered"
                                rel="nofollow"
                                onClick={() => { history.push('./external-links') }}> EXTERNAL LINKS </span>
                        </p>
                        <div className="col-md-6 d-flex justify-content-md-end justify-content-center">
                            <i className="fab fa-facebook-f m-3 text-white" ></i>
                            <i className="fab fa-twitter  m-3 text-white" ></i>
                            <i className="fab fa-instagram m-3 text-white" ></i>
                            <i className="fab fa-pinterest mt-3 mb-3 ms-3 text-white" ></i>
                        </div>
                    </div>
                    <hr style={{ color: 'white' }} />
                </div>
            </footer>
        );
    };
    return ReactDOM.createPortal(render_footerComponent(), footer_root);

}

export default Footer;
