import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import './index.scss'
import { Helmet } from "react-helmet";

const ForgotPassword = (props) => {
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false);
    const [validateEmailErr, setValidateEmailErr] = useState(false);
    const [otp, setOtp] = useState('');
    const [stage, setStage] = useState('Forgot password');
    const [password, setPassword] = useState('');
    const [passwordErr, setPasswordErr] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [cnfPassword, setCnfPassword] = useState('');
    const [showCnfPassword, setShowCnfPassword] = useState(false);
    const [cnfPasswordErr, setCnfPasswordErr] = useState(false);
    const [passwordMatchErr, setPasswordMatchErr] = useState(false);
    const [passwordLengthErr, setPasswordLengthErr] = useState(false);
    const [cnfPasswordLengthErr, setCnfPasswordLengthErr] = useState(false);

    const handleEmailInput = (value) => {
        if (value.length <= 0) {
            setEmailErr(true);
        } else {
            setEmailErr(false);
            if (value.match(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(?:\.\w{2,3})+$/)) {
                setValidateEmailErr(false);
            } else {
                setValidateEmailErr(true);
            };
        }
        setEmail(value);
    };

    const handlePasswordInput = (value) => {
        if (value.length <= 0) {
            setPasswordErr(true);
        } else {
            if (value.length < 6) {
                setPasswordLengthErr(true);
            } else {
                setPasswordLengthErr(false);
            };
            setPasswordErr(false);
        };
        setPassword(value);
    }
    const handleCnfPasswordInput = (value) => {
        if (value.length <= 0) {
            setCnfPasswordErr(true);
        } else {
            if (value.length < 6) {
                setCnfPasswordLengthErr(true);
            } else {
                setCnfPasswordLengthErr(false);
            }
            setCnfPasswordErr(false)
            if (value === password) {
                setPasswordMatchErr(false);
            } else {
                setPasswordMatchErr(true);
            }
        }
        setCnfPassword(value);

    }

    const showHidePassword = () => {
        setShowPassword(!showPassword);
    };
    const showHideCnfPassword = () => {
        setShowCnfPassword(!showCnfPassword);
    };

    const verifyOtp = () => {
        setStage('Verify OTP');
    };

    const createNewPassword = () => {
        setStage('Create New Password')
    }

    const handleChange = (value) => {
        setOtp(value);
    };
    return (
        <div className="container">
              <Helmet>
                <title>Forgot Password | ELL Stores </title>
            </Helmet>
            <div className="row mt-5">
                <div className="col-lg-6 col-md-10 ms-auto me-auto mb-3">
                    <div className="login-form text-center box-shadow white-bg pt-md-5 pt-2 pb-mb-5 pb-3">
                        <h2 className="title mb-4 mt-2 mt-md-0">
                            <span>{stage}</span>
                        </h2>
                        {
                            stage === 'Forgot password' ?
                                <form id="contact-form" className="px-md-5 px-4">
                                    <div className="form-group">
                                        <div className="position-relative">
                                            <i className="fas fa-envelope"></i>
                                            <input id="form_name" type="text" name="email" className="form-control" placeholder="Email"
                                                value={email}
                                                onBlur={(e) => handleEmailInput(e.target.value)}
                                                onChange={(e) => handleEmailInput(e.target.value)}
                                            />
                                        </div>
                                        {
                                            emailErr ?
                                                <div className="text-end text-danger p-1 err_txt">Email is required</div>
                                                : null
                                        }
                                        {
                                            validateEmailErr && !emailErr ?
                                                <div className="text-end text-danger p-1 err_txt">Invalid email</div>
                                                : null
                                        }
                                    </div>
                                    <button
                                        onClick={() => { verifyOtp() }} style={{ width: '100%' }} className="btn btn-theme btn-block">
                                        <span>Get otp</span>
                                    </button>
                                </form>
                                : null
                        }
                        {
                            stage === 'Verify OTP' ?
                                <>
                                    <div className="d-flex justify-content-center my-2">
                                        <OtpInput
                                            value={otp}
                                            onChange={handleChange}
                                            numInputs={4}
                                            inputStyle={{ width: '3em', height: '2.5em' }}
                                            separator={<span>&nbsp; - &nbsp;</span>}
                                        />
                                    </div>
                                    <button onClick={() => { createNewPassword() }} className="btn btn-theme mt-4">Submit</button>
                                </>
                                : null
                        }
                        {
                            stage === 'Create New Password' ?
                                <>
                                    <form id="contact-form" className="px-md-5 px-4">
                                        <div className="form-group">
                                            <div className="position-relative">
                                                <i className="fas fa-envelope"></i>
                                                <input id="form_name" type="text" name="email" className="form-control" placeholder="Email"
                                                    value={email}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="position-relative">
                                                {
                                                    showPassword ?
                                                        <i onClick={() => { showHidePassword() }} className="fa fa-unlock-alt"></i>
                                                        :
                                                        <i onClick={() => { showHidePassword() }} className="fas fa-lock"></i>
                                                }
                                                <input id="form_password" type={showPassword ? "text" : "password"} name="password" className="form-control" placeholder="Password"
                                                    value={password}
                                                    onBlur={(e) => handlePasswordInput(e.target.value)}
                                                    onChange={(e) => handlePasswordInput(e.target.value)}
                                                />
                                            </div>
                                            {
                                                passwordErr ?
                                                    <div className="text-end text-danger p-1 err_txt err_txt">Password is required.</div>
                                                    : null
                                            }
                                            {
                                                passwordLengthErr && !passwordErr ?
                                                    <div className="text-end text-danger p-1 err_txt err_txt">Enter minimum 6 characters.</div>
                                                    : null
                                            }
                                        </div>
                                        <div className="form-group">
                                            <div className="position-relative">
                                                {
                                                    showCnfPassword ?
                                                        <i onClick={() => { showHideCnfPassword() }} className="fa fa-unlock-alt"></i>
                                                        :
                                                        <i onClick={() => { showHideCnfPassword() }} className="fas fa-lock"></i>
                                                }
                                                <input id="form_cnfPassword" type={showCnfPassword ? "text" : "password"} name="password" className="form-control" placeholder="Confirm password"
                                                    value={cnfPassword}
                                                    onBlur={(e) => handleCnfPasswordInput(e.target.value)}
                                                    onChange={(e) => handleCnfPasswordInput(e.target.value)}
                                                />
                                            </div>
                                            {
                                                cnfPasswordErr ?
                                                    <div className="text-end text-danger p-1 err_txt">Confirm password is required.</div>
                                                    : null
                                            }
                                            {
                                                cnfPasswordLengthErr && !cnfPasswordErr ?
                                                    <div className="text-end text-danger p-1 err_txt">Enter minimum 6 characters.</div>
                                                    : null
                                            }
                                            {
                                                passwordMatchErr && !cnfPasswordErr && !cnfPasswordLengthErr ?
                                                    <div className="text-end text-danger p-1 err_txt">Passwords not matching.</div>
                                                    : null
                                            }
                                        </div>
                                        <button onClick={() => { }} className="btn btn-theme">Submit</button>
                                    </form>
                                </>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
