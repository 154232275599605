import React from 'react';
import pageNotFound from '../../Assets/Images/pageNotFound.svg'

const PageNotFound = () => {
    return (
        <div className="text-center h-100">
            <img src={pageNotFound} className="w-25 text-center" alt="404 Page Not Found" />
        </div>
    );
};

export default PageNotFound;
