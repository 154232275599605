import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import derbycaptoe14 from "../Assets/Images/derby-captoe-14.jpg";
import derbycaptoe13 from "../Assets/Images/derby-captoe-13.jpg";
import derbycaptoe15 from "../Assets/Images/derby-captoe-15.jpg";
import derbycaptoe10 from "../Assets/Images/derby-captoe-10.jpg";
import classicBoat2 from "../Assets/Images/classic-boat2.jpg";
import classicBoat5 from "../Assets/Images/classic-boat5.jpg";
import classicBoat1 from "../Assets/Images/classic-boat1.jpg";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <button
      onClick={onClick}
      className="carousel-control-next w-5"
      aria-label="carousel-control-next"

      type="button"
      data-bs-target="#carouselExampleFade"
      data-bs-slide="next"
    >
      <i className="fas fa-angle-right fa-2x" aria-hidden="true"></i>
    </button>
  );
}
function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <button
      className={" carousel-control-prev w-5"}
      aria-label={'carousel-control-prev'}
      onClick={onClick}
      type="button"
      data-bs-target="#carouselExampleFade"
      data-bs-slide="prev"
    >
      <i className="fas fa-angle-left fa-2x" aria-hidden="true"></i>
    </button>
  );
}
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  lazyLoad: true,
  swipe: false,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SlickSlider = (props) => {
  return (
    <>
      <hr />
      <h3 style={{ color: "black" }} className="text-center my-3">
        BEST SELLERS
      </h3>
      <hr />
      <Slider {...settings}>
        <img className="w-96p m-2p border h-auto" src={derbycaptoe14} alt="alernative" />
        <img className="w-96p m-2p border h-auto" src={derbycaptoe13} alt="alernative" />
        <img className="w-96p m-2p border h-auto" src={derbycaptoe15} alt="alernative" />
        <img className="w-96p m-2p border h-auto" src={classicBoat5} alt="alernative" />
        <img className="w-96p m-2p border h-auto" src={classicBoat2} alt="alernative" />
        <img className="w-96p m-2p border h-auto" src={classicBoat1} alt="alernative" />
        <img className="w-96p m-2p border h-auto" src={derbycaptoe10} alt="alernative" />
      </Slider>
    </>
  );
};

export default SlickSlider;
