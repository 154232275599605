const get = async (url) => {
    let resData = await fetch(url)
        .then(res => res.json())
        .then(async (result) => {
            return result;
        })
        .catch((error) => {
            return error;
        });
    return resData;
}

const post = async (url, data ={}, accessToken='') => {
    let resData = await fetch(url, {
        method: "POST",
        headers: {
            "Content-type": 'application/json',
           "Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
    }).then(res => res.json())
        .then(async (result) => {
            return result;
        }).catch((error) => {
            return error;
        });
    return resData;
}

const Request = { get, post };

export default Request;