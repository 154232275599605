import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CarouselComponent from '../../Components/Carousel';
import { Helmet } from "react-helmet";
import jacket from '../../Assets/Images/jacket.png'
import belt_shoes from '../../Assets/Images/belt_shoes.png'
import belt from '../../Assets/Images/belt.png'
import purse from '../../Assets/Images/purse.png'
import './index.scss'
import { getItem, getSessionStorage } from '../../Utils/util';
import SlickSlider from '../../Components/SlickSlider';
import shoes from '../../Assets/Images/shoes.jpg'

const Home = (props) => {
    const history = useHistory();
    const [accessToken, setAccessToken] = useState('');

    useEffect(() => {
        let getData = getItem('accessToken') || getSessionStorage('accessToken');
        setAccessToken(getData);
    }, [accessToken]);

    const naviagteToShop = () => {
        history.push('/shop');
    };

    return (
        <div>
            <Helmet>
                <title>The Best Online Shoe Store for Men | ELL Stores</title>
                <meta name="description" content="ELL Stores offers the best online shoe for Men. We are unequivocally passionate about fine handloom-quality accessories, and we want to share that passion with our fellow shoe enthusiasts." />
            </Helmet>
            <div className="col-md-12">
                <div className="h-100">
                    <CarouselComponent />
                </div>
                <div className="container">
                    <div className="my-5 col-md-12 row ">
                        <div className="col-md-4 d-flex " onClick={() => { }} >
                            <div className="position-relative image_hover border d-flex align-items-center">
                                <img src={jacket} className="d-block w-100 h-auto" alt="firstImg" />
                                <div className="position-absolute text_on_image">
                                    {/* <h3>Shop now for More {'>>'}</h3> */}
                                    <h3>Coming Soon {'!'}</h3>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4" >
                            <div className="position-relative image_hover border" onClick={() => { naviagteToShop() }}>
                                <img src={belt_shoes} className="d-block w-100 h-auto " alt="firstImg" style={{ borderRadius: '4px' }} />
                                <div className="position-absolute text_on_image">
                                    <h3>Shop now for More {'>>'}</h3>
                                </div>
                            </div>
                            <div className="position-relative image_hover border mt-4">
                                <img src={purse} className="d-block w-100 h-auto" alt="firstImg" style={{ borderRadius: '4px' }} />
                                <div className="position-absolute text_on_image">
                                    {/* <h3>Shop now for More {'>>'}</h3> */}
                                    <h3>Coming Soon {'!'}</h3>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="position-relative image_hover border">
                                <img src={belt} className="d-block w-100 h-auto" alt="firstImg" style={{ borderRadius: '4px' }} />
                                <div className="position-absolute text_on_image">
                                    {/* <h3>Shop now for More {'>>'}</h3> */}
                                    <h3>Coming Soon {'!'}</h3>
                                </div>
                            </div>
                            <div className="position-relative image_hover border mt-4" onClick={() => { naviagteToShop() }} >
                                <img src={shoes} className="d-block w-100 h-auto" alt="firstImg" style={{ borderRadius: '4px' }} />
                                <div className="position-absolute text_on_image">
                                    <h3>Shop now for More {'>>'}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SlickSlider />
                </div>
            </div>
        </div>
    );
};

export default Home;
