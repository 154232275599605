export const PRODUCT_CATEGORY = [
    {
        "id": 1,
        "value": "Men's shoes",
        "name": "Men's shoes"
    }
];

export const SPECIFICATIONS = [
    {
        "specification_name": "Material",
        "specification_value": ""
    },
    {
        "specification_name": "Style",
        "specification_value": ""
    },
    {
        "specification_name": "Last",
        "specification_value": ""
    }
];

export const PRODUCT_SIZE = [
    {
        'id': '1',
        'value': '6'
    },
    {
        'id': '2',
        'value': '7'
    },
    {
        'id': '3',
        'value': '8'
    },
    {
        'id': '4',
        'value': '9'
    }
]
