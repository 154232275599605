import React, { useState, useEffect } from 'react';
import './index.scss'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { toast } from 'react-toastify';
import { clearLocalStorage, getItem, getSessionStorage, renderPrice } from '../../Utils/util'; //clearLocalStorage
import { API } from '../../Api/API';
import { useHistory } from 'react-router-dom';
import flipcart_icon from '../../Assets/Images/flipkart.svg'
import amazon_icon from '../../Assets/Images/amazon.svg'
import Loader from '../../Components/Loader';
import "react-image-gallery/styles/css/image-gallery.css";
import ProductGallery from '../../Components/ProductGallery';
import { Helmet } from "react-helmet";
import { PRODUCT_SIZE } from '../../Utils/Constants';

const ProductDetails = (props) => {
    const { getData } = props;
    const history = useHistory();
    const [accessToken, setAccessToken] = useState('');
    const [userInfo, setUserInfo] = useState('')
    const [dropDownValue, setDropDownValue] = useState('Select Size');
    const [message, setMessage] = useState('');
    const [messageErr, setMessageErr] = useState(false);
    const [carouselImages, setCarouselImages] = useState([]);
    const [productComments, setProductComments] = useState([]);
    const [hideBtn, setHideBtn] = useState(false);
    const [showWishListBtn, setShowWishListBtn] = useState(false);
    const [loading, setLoading] = useState(true);

    const productItem = props.location.state.productItem;

    let specifications = JSON.parse(productItem.specifications);
    let buyAt = JSON.parse(productItem.buy_at)

    const getProductComments = async (productItem) => {
        let result = await API.getProductComments(productItem.id);
        if (result && result.success) {
            let { data } = result;
            if (data) {
                setProductComments(data);
            } else {
                toast.error('Something went wrong, please try again');
            };
        };
    };

    const getProductById = async (userId, productId) => {
        setLoading(true);
        let result = await API.getProductsById(userId, productId);
        if (result && result.success) {
            let { data } = result;
            if (data) {
                setShowWishListBtn(data.wishlist_status);
            } else {
                toast.error('Something went wrong, please try again');
            };
        };
        setLoading(false);
    };

    useEffect(() => {
        let getData = getItem('accessToken') || getSessionStorage('accessToken');
        setAccessToken(getData);
        let getUserInfo = getItem('userInfo') || getSessionStorage('userInfo');
        setUserInfo(getUserInfo);
        if (productItem) {
            let userData = JSON.parse(getUserInfo);
            let userId = userData ? userData.id : 0;
            getProductById(userId, productItem.id);
            let imagesArray = JSON.parse(productItem.media);
            setCarouselImages(imagesArray);
            getProductComments(productItem);
        }
    }, [accessToken, userInfo, productItem]);

    const handleDropdownValue = (value) => {
        setDropDownValue(value);
    };

    const handleMessageInput = (value) => {
        if (value.length <= 0) {
            setMessageErr(true);
        } else {
            setMessageErr(false);
        };
        setMessage(value);
    };

    const addToWishList = async () => {
        if (accessToken) {
            let userData = JSON.parse(userInfo);
            let result = await API.addToWishList(userData.id, productItem.id, accessToken);
            if (result.message === "Invalid token") {
                clearLocalStorage();
                getData();
                history.push('./login');
            }
            if (result && result.success) {
                if (result.message === "Invalid token") {
                    clearLocalStorage();
                    getData();
                    history.push('./login');
                };
                let { data } = result
                if (data.status === 1) {
                    setShowWishListBtn(true);
                    toast.success('Added successfully to wishlist');
                } else {
                    toast.error('Something went wrong, please try again');
                };
            }
        } else {
            history.push('./login');
        };
    };

    const removeWishListApi = async () => {
        let userData = JSON.parse(userInfo);
        let result = await API.removeFromWishList(userData.id, productItem.id, accessToken);
        if (result && result.success) {
            if (result.message === "Invalid token") {
                clearLocalStorage();
                getData();
                history.push('./login');
            };
            let { data } = result;
            if (data.status === 1) {
                setShowWishListBtn(false);
                toast.success('Removed successfully');
            } else {
                toast.error('Something went wrong, please try again');
            };
        };
    };

    const postComment = async () => {
        if (message.length >= 4) {
            setMessageErr(false);
            if (accessToken) {
                let userData = JSON.parse(userInfo);
                let result = await API.postCommentForProduct(userData.id, productItem.id, accessToken, message);
                if (result.message === "Invalid token") {
                    clearLocalStorage();
                    getData();
                    history.push('./login');
                };
                if (result && result.success) {
                    let { data } = result;
                    if (data.status) {
                        setMessage('');
                        toast.success(data.message);
                        getProductComments(productItem);
                    } else {
                        toast.error('Something went wrong, please try again');
                    };
                };
            } else {
                toast.error('Please login');
                setMessage('');
            };
        } else {
            setMessageErr(true);
        };
    };

    const hideBuyNowBtn = (index) => {
        setHideBtn(true);
    };

    const showBuyNowBtn = () => {
        setHideBtn(false);
    };

    if (loading) {
        return <Loader />;
    } else {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{productItem.title} | ELL Stores</title>
                </Helmet>

                <div className="container">
                    <div className="row col-md-12 mt-4">
                        <div className="col-md-6 h-100">
                            {
                                carouselImages && carouselImages.length > 0 ?
                                    <ProductGallery images={carouselImages} />
                                    : null
                            }
                        </div>
                        <div className="col-md-6 mt-3 h-100 position-relative">
                            <div id="myPortal" className="onHover_zoom" />
                            <h4 className="page_title_ell_boat">{productItem.title}</h4>
                            <h5 className="my-4" style={{ color: '#221b1b' }}>&#8377; {renderPrice(productItem.price)} <span className="price_del">&#8377; {renderPrice(productItem.initial_price)}</span></h5>
                            <div className="dropdown my-4">
                                <button className="dropdown-toggle px-2 py-2 size_dropdown text-left" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    {dropDownValue}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li className="p-2 select_size_txt" onClick={(e) => { handleDropdownValue('Select Size') }}>Select Size</li>
                                    {
                                        PRODUCT_SIZE.map((item, index) => (
                                            <li className="p-2 select_size" onClick={() => { handleDropdownValue(item.value) }} key={item.value} id={index}>{item.value}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className="my-4 py-1">
                                {
                                    showWishListBtn ?
                                        <button className="btn btn-theme items"
                                            onClick={() => { removeWishListApi() }}
                                        >Remove</button>
                                        :
                                        <button className="btn btn-theme items"
                                            onClick={() => { addToWishList() }}
                                        >Wish for it</button>
                                }
                                {
                                    hideBtn ?
                                        <>

                                            <div className="d-inline-block ms-5 position-relative">
                                                <a href={buyAt.amazon} target="_blank" rel="noreferrer">
                                                    <img src={amazon_icon} style={{ width: '37px' }} className="d-inline-block pe_cursor" alt="amazon" />
                                                </a>
                                                <a href={buyAt.flipkart} target="_blank" rel="noreferrer">
                                                    <img src={flipcart_icon} style={{ width: '37px' }} className="d-inline-block mx-2 pe_cursor" alt="flipcart" />
                                                </a>
                                                <div onClick={() => { showBuyNowBtn() }}
                                                    className="position-absolute close_social_icon pe_cursor">
                                                    {/* <i className="far fa-times-circle"></i> */}
                                                    <i className="fas fa-chevron-left"></i>
                                                </div>
                                            </div>

                                        </>
                                        :
                                        <button onClick={() => { hideBuyNowBtn() }} className="btn btn-theme items">buy now</button>
                                }
                            </div>
                            <hr />
                            <h6 className="mb-3">
                                <span className="text-theme">Product Description</span>
                            </h6>
                            <hr />
                            <p className="text-justify product_desc mt-4">{productItem.description}</p>
                            <div className="mt-3">
                                {
                                    specifications && Object.keys(specifications).map((item, index) => (
                                        <ul key={item} className="portfolio-meta">
                                            <li><span className="text-capitalize">{item}</span> : {specifications[item]}</li>
                                        </ul>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row col-md-12 mt-2">
                        <div className="col-md-6 h-100">
                            <hr />
                            <h6 className="mb-3">
                                <span className="text-theme">Comments</span>
                            </h6>
                            <hr />
                            <div className="row mb-3">
                                {
                                    productComments && productComments.length > 0 ? productComments && productComments.map((item, index) => (
                                        <div className="mb-1" key={index}>
                                            <div className="d-flex align-items-center">
                                                <span className="me-1">
                                                    <i className="fas fa-user-circle" style={{ color: '#adb5bd', fontSize: '1.5rem' }}></i>
                                                </span>
                                                <span className="me-2 text-capitalize">{item.name}</span>
                                                <span className="commented_time">({item.commented_at})</span>
                                            </div>
                                            <p className="text-justify" style={{ fontSize: '12px' }}>{item.comment}</p>
                                        </div>
                                    )) :
                                        <div>No Comments</div>
                                }
                            </div>
                        </div>
                        <div className="col-md-6 h-100">
                            <hr />
                            <h6 className="mb-3">
                                <span className="text-theme">Write a comment</span>
                            </h6>
                            <hr />
                            <div className="row mb-3">
                                <div>
                                    <textarea id="form_message" name="message" className="form-control" placeholder="Message"
                                        style={{ height: '150px', resize: 'none', border: '0.5px solid #dee2e6' }} rows="10"
                                        value={message}
                                        onBlur={(e) => handleMessageInput(e.target.value)}
                                        onChange={(e) => handleMessageInput(e.target.value)}
                                    ></textarea>
                                    {
                                        messageErr ?
                                            <div className="text-end text-danger p-1 err_txt">Please leave us a message.</div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="text-end mb-3">
                                <button onClick={() => { postComment() }} className="btn btn-theme float-right"><span>Submit</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
};

export default ProductDetails;
