
import Request from "./BaseApiServices"
import { ACTIONS, BaseURL } from './NetworkConstants'

const login = async (email, password) => {
    let data = { email, password };
    let response = await Request.post(BaseURL + ACTIONS.USER_LOGIN, data);
    return response;
}

const signup = async (name, email, password, phone_num) => {
    let data = { name, email, password, phone_num };
    let response = await Request.post(BaseURL + ACTIONS.SIGNUP, data);
    return response;
}

const getAllProducts = async () => {
    let response = await Request.post(BaseURL + ACTIONS.PRODUCTS);
    return response;
}

const addToWishList = async (user_id, product_id, accessToken) => {
    let data = { user_id, product_id };
    let response = await Request.post(BaseURL + ACTIONS.ADD_TO_WISHLIST, data, accessToken);
    return response
}

const getWishListProducts = async (user_id, accessToken) => {
    let response = await Request.post(BaseURL + ACTIONS.GET_WISHLIST_PRODUCTS + user_id, {}, accessToken);
    return response;
}

const removeFromWishList = async (user_id, product_id, accessToken) => {
    let data = { user_id, product_id };
    let response = await Request.post(BaseURL + ACTIONS.REMOVE_WISHLIST_API, data, accessToken);
    return response;
}

const postCommentForProduct = async (user_id, product_id, accessToken, comment) => {
    let data = { user_id, product_id, comment1: comment };
    let response = await Request.post(BaseURL + ACTIONS.POST_COMMENT, data, accessToken);
    return response;
}

const getProductComments = async (product_id) => {
    let response = await Request.post(BaseURL + ACTIONS.GET_PRODUCT_COMMENTS + product_id, {}, '');
    return response;
}

const contactUs = async (username, email, phone_number, subject, message) => {
    let data = { username, email, phone_number, subject, message };
    let response = await Request.post(BaseURL + ACTIONS.CONTACT_US, data);
    return response;
}

const subscribe = async (email, accessToken) => {
    let data = { email };
    let response = await Request.post(BaseURL + ACTIONS.SUBSCRIBE, data, accessToken);
    return response;
}

const getProductsById = async (user_id, product_id) => {
    let data = { user_id, product_id };
    let response = await Request.post(BaseURL + ACTIONS.PRODUCT_BY_ID, data);
    return response;

}

const clearImages = async () => {
    let response = await Request.get(BaseURL + ACTIONS.CLEAR_IMAGES);
    return response;
}

const bestSellers = async () => {
    let response = await Request.get(BaseURL + ACTIONS.BEST_SELLERS);
    return response;
}

export const API = {
    login,
    signup,
    getAllProducts,
    addToWishList,
    getWishListProducts,
    removeFromWishList,
    postCommentForProduct,
    getProductComments,
    contactUs,
    subscribe,
    getProductsById,
    clearImages,
    bestSellers
};
