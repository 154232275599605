export const setItem = (k, v) => {
    localStorage.setItem(k, v);
}
export const getItem = (k) => {
    return localStorage.getItem(k);
}

export const setSessionStorage = (k, v) => {
    window.sessionStorage.setItem(k, v);
}

export const getSessionStorage = (k) =>{
   return sessionStorage.getItem(k);
}

export const renderPrice = (priceValue) => {
    let n = priceValue;
    let str = n.toLocaleString("en-IN");
    let price = str + ".00";
    return price;
}

export const clearLocalStorage = () => {
    localStorage.clear();
}

export const clearSessionStorage = () =>{
    sessionStorage.clear()
}
