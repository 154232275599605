/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Switch, Route } from "react-router-dom";
import About from './Screens/About';
import Contact from './Screens/Contact';
import Home from './Screens/Home';
import Login from './Screens/Login';
import PageNotFound from './Screens/PageNotFound';
import SignUp from './Screens/SignUp';
import Wishlist from './Screens/WishList';
import Shop from './Screens/Shop';
import ProductDetails from './Screens/ProductDetails';
import { Footer, NavBar } from './Components';
import { getItem, getSessionStorage } from './Utils/util';
import ForgotPassword from './Screens/ForgotPassword';
import CreateProduct from './Screens/Admin/CreateProduct';
import TermsAndConditions from './Screens/TermsAndConditions';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import ExternalLinks from './Screens/ExternalLinks';

const Router = (props) => {
    const [accessToken, setAccessToken] = useState('');
    const [role, setRole] = useState('');

    const getData = () => {
        let getData = getItem('accessToken') || getSessionStorage('accessToken');
        setAccessToken(getData);
        let getUserInfo = getItem('userInfo') || getSessionStorage('userInfo');
        let userData = JSON.parse(getUserInfo);
        setRole(userData ? userData.role : '');
    };

    useEffect(() => {
        getData();
    });

    return (
        <>
            <NavBar loginAccessToken={accessToken}  {...props} />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/home" component={Home} />
                <Route exact path="/about-us" component={About} />
                <Route exact path="/contact-us" component={Contact} />
                <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/external-links" component={ExternalLinks} />
                <Route exact path="/login" component={(props) => <Login {...props} getData={getData} />} />
                <Route exact path="/shop" component={(props) => <Shop {...props} getData={getData} role={role}/>} />
                <Route exact path="/product-details" component={(props) => <ProductDetails {...props} getData={getData} />} />
                <Route exact path="/sign-up" component={(props) => <SignUp {...props} getData={getData} />} />
                {
                    accessToken ?
                        <Route exact path="/wishlist" component={(props) => <Wishlist {...props} getData={getData} />} />
                        : null
                }
                {
                    role === 'Admin' ?
                    <React.Fragment>
                    <Route exact path="/create-product" component={(props) => <CreateProduct {...props} getData={getData} />} />
                    <Route exact path="/update-product" component={(props) => <CreateProduct {...props} getData={getData} />} />
                    {/* <Route path="*" component={PageNotFound} /> */}
                    </React.Fragment>
                    : null
                }
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="*" component={PageNotFound} />
            </Switch>
            <Footer loginAccessToken={accessToken} getData={getData}  {...props} />
        </>
    );
}

export default Router;
