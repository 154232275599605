import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "../../../Api/API";
import { ACTIONS, BaseURL } from "../../../Api/NetworkConstants";
import { PRODUCT_CATEGORY, SPECIFICATIONS } from "../../../Utils/Constants";
import { getItem, getSessionStorage } from "../../../Utils/util";
import { Helmet } from "react-helmet";
import './index.scss';

const CreateProduct = (props) => {
    let editProduct = props && props.history && props.history.location.state && props.history.location.state.product;
    let buyAt = editProduct && editProduct.buy_at ? JSON.parse(editProduct.buy_at) : null;

    const inpurtRef = useRef();
    const history = useHistory();

    const [accessToken, setAccessToken] = useState('');
    const [productName, setProductName] = useState(editProduct ? editProduct.name : '');
    const [productNameErr, setProductNameErr] = useState(false);
    const [productTitle, setProductTitle] = useState(editProduct ? editProduct.title : '');
    const [productTitleErr, setProductTItleErr] = useState(false);
    const [description, setDescription] = useState(editProduct ? editProduct.description : '');
    const [productColor, setProductColor] = useState(editProduct ? editProduct.color : '');
    const [descriptionErr, setDescriptionErr] = useState(false);
    const [dropDownValue, setDropDownValue] = useState(editProduct ? editProduct.category : 'Select Category');
    const [price, setPrice] = useState(editProduct ? editProduct.price : '');
    const [priceErr, setPriceErr] = useState(false);
    const [initialPrice, setInitialPrice] = useState(editProduct ? editProduct.initial_price : '');
    const [initialPriceErr, setInitialPriceErr] = useState('');
    const [amazonUrl, setAmazonUrl] = useState(editProduct ? buyAt.amazon : '');
    const [flipkartUrl, setFlipkartUrl] = useState(editProduct ? buyAt.flipkart : '');
    const [productSpecification, setProductSpecifications] = useState(SPECIFICATIONS);
    const [change, setChange] = useState(false);
    const [images, setImages] = useState([]);
    const [storeImgs, setStoreImgs] = useState([]);

    useEffect(() => {
        let getData = getItem('accessToken') || getSessionStorage('accessToken');
        setAccessToken(getData);
        if (editProduct) {
            let newSpecificationArray = [];
            let editSpecification = JSON.parse(editProduct.specifications);
            let editSpecificationArray = Object.entries(editSpecification);
            editSpecificationArray.map((item, index) => {
                let data = {
                    'specification_name': item[0],
                    'specification_value': item[1]
                };
                newSpecificationArray.push(data);
                return newSpecificationArray;
            })
            setProductSpecifications(newSpecificationArray);
        }
    }, [accessToken, editProduct]);

    const handleProductNameInput = (value) => {
        if (value.length <= 0) {
            setProductNameErr(true);
        } else {
            setProductNameErr(false);
        }
        setProductName(value);
    };

    const handleProductTitleInput = (value) => {
        if (value.length <= 0) {
            setProductTItleErr(true);
        } else {
            setProductTItleErr(false);
        }
        setProductTitle(value);
    };

    const handleProductColorInput = (value) => {
        setProductColor(value);
    };

    const handleDescriptionInput = (value) => {
        if (value.length <= 0) {
            setDescriptionErr(true);
        } else {
            setDescriptionErr(false);
        }
        setDescription(value);
    };

    const handleDropdownValue = (value) => {
        setDropDownValue(value);
    };

    const handleProductPriceInput = (value) => {
        if (value.length <= 0) {
            setPriceErr(true);
        } else {
            setPriceErr(false);
        }
        setPrice(value);
    };

    const handleInitialPriceInput = (value) => {
        if (value.length <= 0) {
            setInitialPriceErr(true);
        } else {
            setInitialPriceErr(false);
        }
        setInitialPrice(value);
    };

    const handleAmazonUrlInput = (value) => {
        setAmazonUrl(value);
    };

    const handleFlipkartUrlInput = (value) => {
        setFlipkartUrl(value);
    };

    const handleSpecificationValue = (value, index) => {
        let changeSpecifications = productSpecification;
        changeSpecifications.map((item, specificIndex) => {
            if (specificIndex === index) {
                item.specification_value = value;
            }
            return item;
        }
        );
        setProductSpecifications(changeSpecifications);
        setChange(!change);
    };

    const addSpecificationKey = (value, index) => {
        let changeSpecifications = productSpecification;
        changeSpecifications.map((item, specificIndex) => {
            if (specificIndex === index) {
                item.specification_name = value;
            }
            return item;
        })
        setProductSpecifications(changeSpecifications);
        setChange(!change);
    };

    const uploadImage = (e) => {
        let fileArray = Array.from(e.target.files);
        let imageArray = [];
        fileArray.map((item) => {
            let addedImage = URL.createObjectURL(item);
            imageArray.push(addedImage);
            return imageArray;
        })
        setImages(imageArray);
        setStoreImgs(fileArray);
    };

    const clearImagesData = async () => {
        let result = await API.clearImages();
        if (result.status) {
            history.push('./shop');
        };
    };

    const addNewSpecification = () => {
        let newArray = productSpecification;
        let newSpecification = {
            "specification_name": "",
            "specification_value": ""
        };
        newArray.push(newSpecification);
        setProductSpecifications(newArray);
        setChange(!change);
    };

    const onSubmitForm = (e) => {
        e.preventDefault();
        if (productName && !productNameErr && productTitle && !productTitleErr && description && productColor &&
            !descriptionErr && dropDownValue !== "Select Category" && dropDownValue !== "" && price && !priceErr &&
            initialPrice && !initialPriceErr && amazonUrl && flipkartUrl && editProduct ? images : images.length > 0) {
            var formData = new FormData();
            let categoryType;
            if (dropDownValue === "Men's shoes") {
                categoryType = "1";
            };
            if (storeImgs.length > 0) {
                storeImgs.forEach(file => {
                    formData.append("images", file);
                });
            };
            let buyAt = {
                amazon: amazonUrl,
                flipkart: flipkartUrl
            };
            let newSpecifications = productSpecification;
            let specificationsData = {};
            newSpecifications.forEach((item, index) => {
                specificationsData[item.specification_name] = item.specification_value;
            });
            formData.append("name", productName);
            formData.append("description", description);
            formData.append("category_type", categoryType);
            formData.append("initial_price", initialPrice);
            formData.append("price", price);
            formData.append("specifications", JSON.stringify(specificationsData));
            formData.append("buy_at", JSON.stringify(buyAt));
            formData.append("title", productTitle);
            formData.append("color", productColor);
            if (editProduct) {
                formData.append('product_id', editProduct.id)
            }

            fetch(BaseURL + `${editProduct ? ACTIONS.UPDATE_PRODUCT : ACTIONS.ADD_PRODUCT}`, {
                method: 'POST',
                body: formData,
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                },
            },
            ).then(res => res.json())
                .then(
                    (result) => {
                        if (result) {
                            setProductName('');
                            setProductTitle('');
                            setDescription('');
                            setProductColor('');
                            setDropDownValue('Select Category');
                            setPrice('');
                            setInitialPrice('');
                            setAmazonUrl('');
                            setFlipkartUrl('');
                            setProductSpecifications(SPECIFICATIONS);
                            setImages([]);
                            setStoreImgs([]);
                            inpurtRef.current.value = "";
                            clearImagesData();
                        };
                    });
        }
        else {
            toast.error('Please Enter Valid data');
        };
    };

    return (
        <div className="container">
            <Helmet>
                <title>
                    {
                        editProduct ?
                            ' Update Product'
                            :
                            'Create Product'
                    }
                    | ELL Stores </title>
            </Helmet>
            <div className="row mt-5">
                <div className="col-lg-12 col-md-10 ms-auto me-auto mb-3">
                    <div className="login-form text-center box-shadow white-bg pt-md-4 pt-2 pb-5">
                        <h2 className="title mb-4">
                            {
                                editProduct ?
                                    <span>Update Product</span>
                                    :
                                    <span>Create Product</span>
                            }
                        </h2>
                        <form id="contact-form" className="px-md-5 px-4 pb-2">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input id="form_name" type="text" name="name" className="form-control" placeholder="Product name"
                                            value={productName}
                                            onBlur={(e) => handleProductNameInput(e.target.value)}
                                            onChange={(e) => handleProductNameInput(e.target.value)}
                                        />
                                        {
                                            productNameErr ?
                                                <div className="text-end text-danger p-1 err_txt">Product name is required.</div>
                                                : null
                                        }
                                    </div>
                                    <div className="form-group">
                                        <input id="product_title" type="text" name="product_title" className="form-control" placeholder="Product title"
                                            value={productTitle}
                                            onBlur={(e) => handleProductTitleInput(e.target.value)}
                                            onChange={(e) => handleProductTitleInput(e.target.value)}
                                        />
                                        {
                                            productTitleErr ?
                                                <div className="text-end text-danger p-1 err_txt">Product title is required.</div>
                                                : null
                                        }
                                    </div>
                                    <div className="form-group">
                                        <textarea id="form_description" name="description" className="form-control" placeholder="Description" style={{ height: '150px', resize: 'none' }} rows="10"
                                            value={description}
                                            onBlur={(e) => handleDescriptionInput(e.target.value)}
                                            onChange={(e) => handleDescriptionInput(e.target.value)}
                                        ></textarea>
                                        {
                                            descriptionErr ?
                                                <div className="text-end text-danger p-1 err_txt">Please describe about the product.</div>
                                                : null
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <input id="price" type="text" name="price" className="form-control" placeholder="Price"
                                                value={price}
                                                onBlur={(e) => handleProductPriceInput(e.target.value)}
                                                onChange={(e) => handleProductPriceInput(e.target.value)}
                                            />
                                            {
                                                priceErr ?
                                                    <div className="text-end text-danger p-1 err_txt">Please enter the price.</div>
                                                    : null
                                            }
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <input id="initial_price" type="text" name="initial_price" className="form-control" placeholder="Initial price"
                                                value={initialPrice}
                                                onBlur={(e) => handleInitialPriceInput(e.target.value)}
                                                onChange={(e) => handleInitialPriceInput(e.target.value)}
                                            />
                                            {
                                                initialPriceErr ?
                                                    <div className="text-end text-danger p-1 err_txt">Please enter the initial price.</div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">

                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <input id="product_color" type="text" name="product_color" className="form-control" placeholder="Color"
                                                value={productColor}
                                                onBlur={(e) => handleProductColorInput(e.target.value)}
                                                onChange={(e) => handleProductColorInput(e.target.value)}
                                            />
                                        </div>
                                        <div className="dropdown col-lg-6">
                                            <button className="dropdown-toggle px-2 py-2 size_dropdown float-md-end" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                {dropDownValue}
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <li className="p-2 select_size_txt" onClick={(e) => { handleDropdownValue('Select Category') }}>Select Category</li>
                                                {
                                                    PRODUCT_CATEGORY.map((item, index) => (
                                                        <li className="p-2 select_size" onClick={() => { handleDropdownValue(item.value) }} key={item.value} id={index}>{item.name}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 text-start my-2">
                                        Specifications
                                        <i onClick={() => { addNewSpecification() }} className="fas fa-plus-circle ms-3 pe_cursor" title="Add Specifications"></i>
                                    </div>
                                    {
                                        productSpecification.map((item, index) => (
                                            <div key={item.specification_name} className="row">
                                                <div className="form-group col-lg-6">
                                                    <input autoFocus id="specification_key" type="text" name="specification_key" className="fw-500 form-control" placeholder="Add Specification"
                                                        value={item.specification_name}
                                                        onChange={(e) => addSpecificationKey(e.target.value, index)}
                                                        onBlur={(e) => addSpecificationKey(e.target.value, index)}
                                                    />
                                                </div>
                                                <div className="form-group col-lg-6">
                                                    <input id="specification_value" type="text" name="specification_value" className="form-control" placeholder='Add value'
                                                        value={item.specification_value}
                                                        onBlur={(e) => handleSpecificationValue(e.target.value, index)}
                                                        onChange={(e) => handleSpecificationValue(e.target.value, index)}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className="form-group col-lg-12">
                                        <input id="amazon_url" type="text" name="amazon_url" className="form-control" placeholder="Amazon url"
                                            value={amazonUrl}
                                            onBlur={(e) => handleAmazonUrlInput(e.target.value)}
                                            onChange={(e) => handleAmazonUrlInput(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group col-lg-12">
                                        <input id="flipkart_url" type="text" name="flipkart_url" className="form-control" placeholder="Flipkart url"
                                            value={flipkartUrl}
                                            onBlur={(e) => handleFlipkartUrlInput(e.target.value)}
                                            onChange={(e) => handleFlipkartUrlInput(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 d-flex mb-3">
                                {
                                    images.map((item, index) => (
                                        <div className="me-2 border">
                                            <img src={item} style={{ width: '120px' }} onClick={() => { }} alt="logo" />
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="col-lg-12 text-start">
                                <input type="file" ref={inpurtRef} name="file" onChange={(e) => { uploadImage(e) }} multiple />
                            </div>
                            <button className="btn btn-theme float-end mt-2"
                                onClick={(e) => { onSubmitForm(e) }}>
                                {
                                    editProduct ? 'Update Product' : 'Add Product'
                                }
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateProduct;
