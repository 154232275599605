import React from 'react';
import './index.scss'
import { useHistory } from 'react-router-dom';
import { BaseURL } from '../Api/NetworkConstants';
import { renderPrice } from '../Utils/util';

const Product = (props) => {
    const { productItem, role } = props;
    let thumbnail = JSON.parse(productItem.media)[0].thumbnail;

    const history = useHistory();

    const editProductItem = () => {
        history.push('/update-product', { product: productItem });
    };

    return (
        <div className="col-lg-3 col-md-6">
            <div className="cat-wrap" id={productItem.id}>
                <img
                    src={BaseURL + thumbnail}
                    alt={productItem.name}
                    className="cat-thumb-img"
                />
                <div className="overlay">
                    <div className="hpb-thumb-txt">
                        {
                            role === 'Admin' ?
                                <div onClick={() => { editProductItem() }} className="edit_icon pe_cursor">
                                    <i className="fas fa-edit"></i>
                                </div>
                                :
                                null
                        }
                        <div className="productTextHoverd">
                            <div className="productTitle">{productItem.name}</div>
                            <div className="productTitle mt-md-1">{productItem.color}</div>
                        </div>
                        <div className="indian_rupee mt-md-1">&#8377; {renderPrice(productItem.price)}</div>
                        <button className="mt-md-2 text-white view_details_btn"
                            onClick={() => history.push({ pathname: '/product-details', state: { productItem: productItem } })}
                        >VIEW DETAILS</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Product;
