import React from "react";
import ReactLoading from "react-loading";

const Loader = (props) => {
    return (
        <div className="d-flex justify-content-center m-5">
            <ReactLoading type={'spokes'} color="#000" />
        </div>
    );
};

export default Loader;
