const Environment = {
    // DEV: "http://localhost:81",
    DEV: "https://ellstores.com:81"
};

const BaseURL = Environment.DEV;

const ACTIONS = {
    USER_LOGIN: '/users/login',
    SIGNUP: '/users/sign-up',
    PRODUCTS: '/products',
    ADD_TO_WISHLIST: '/wishlist/add',
    GET_WISHLIST_PRODUCTS: '/wishlist/',
    REMOVE_WISHLIST_API: '/wishlist/remove',
    POST_COMMENT: '/products/comments/add-new',
    GET_PRODUCT_COMMENTS: '/products/comments/',
    CONTACT_US: '/contact/us',
    SUBSCRIBE: '/contact/subscribe',
    PRODUCT_BY_ID: '/products/get-product',
    ADD_PRODUCT: '/products/add-new',
    UPDATE_PRODUCT:'/products/update',
    CLEAR_IMAGES: '/products/clear-images',
    BEST_SELLERS:'/products/best-sellers'
}

export { BaseURL, ACTIONS };
