import React, { useEffect, useState } from 'react';
import headerBg from '../Assets/Images/leather-bg.jpg'
import logo from '../Assets/Images/logo.png'
import { clearLocalStorage, clearSessionStorage, getItem, getSessionStorage } from '../Utils/util';
import { Link, useHistory } from "react-router-dom";

const NavBar = (props) => {
    const history = useHistory();

    const { loginAccessToken } = props;
    const [accessToken, setAccessToken] = useState(loginAccessToken);
    const [activeTab, setActiveTab] = useState('home');
    const [role, setRole] = useState('');

    const getAccessToken = () => {
        let getData = getItem('accessToken') || getSessionStorage('accessToken');
        setAccessToken(getData);
        let getUserInfo = getItem('userInfo') || getSessionStorage('userInfo');
        let userData = JSON.parse(getUserInfo);
        setRole(userData ? userData.role : '');
    };

    useEffect(() => {
        getAccessToken();
        let pathName = history.location.pathname;
        if (pathName.length <= 1) {
            pathName = '/home'
        }
        setActiveTab(pathName.substring(1));
    }, [loginAccessToken, history]);

    const doLogout = () => {
        clearLocalStorage();
        clearSessionStorage();
        setAccessToken('');
        history.push('./home');
    };

    const handleActiveTab = (value) => {
        setActiveTab(value);
    };

    const navigateToHome = () => {
        history.push('./home');
        setActiveTab('home');
    };

    return (
        <div className="fixed_header" style={{ backgroundImage: `url(${headerBg})` }}>
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <img src={logo} onClick={() => { navigateToHome() }} className=" h-auto d-block py-lg-0 py-1 navbar_logo pe_cursor" alt="logo" />
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fas fa-bars" style={{ color: '#bdb6b6', fontSize: '22px' }}></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li onClick={() => { handleActiveTab('home') }} className={`my-1 my-md-0 nav-item ${activeTab === 'home' ? "active" : ""}`}>
                                    <Link to="/home" className="nav-link pt-1">HOME</Link>
                                </li>
                                <li onClick={() => { handleActiveTab('shop') }} className={`my-1 my-md-0 nav-item ${activeTab === 'shop' ? "active" : ""}`}>
                                    <Link to="/shop" className="nav-link pt-1">SHOP</Link>
                                </li>
                                <li onClick={() => { handleActiveTab('about-us') }} className={`my-1 my-md-0 nav-item ${activeTab === 'about-us' ? "active" : ""}`}>
                                    <Link to="/about-us" className="nav-link pt-1">ABOUT</Link>
                                </li>
                                <li onClick={() => { handleActiveTab('contact-us') }} className={`my-1 my-md-0 nav-item ${activeTab === 'contact-us' ? "active" : ""}`}>
                                    <Link to="/contact-us" className="nav-link pt-1">CONTACT</Link>
                                </li>
                                {
                                    accessToken && accessToken != null && accessToken !== "" && role && role === 'Admin' ?
                                        <li onClick={() => { handleActiveTab('create-product') }} className={`my-1 my-md-0 nav-item ${activeTab === 'create-product' ? "active" : ""}`}>
                                            <Link to="/create-product" className="nav-link pt-1">Create Product</Link>
                                        </li>
                                        : null
                                }
                                {
                                    accessToken && accessToken != null && accessToken !== "" ?
                                        <>
                                            <li onClick={() => { handleActiveTab('wishlist') }} className={`my-1 my-md-0 nav-item ${activeTab === 'wishlist' ? "active" : ""}`}>
                                                <Link to="/wishlist" className="nav-link pt-1">WISHLIST</Link>
                                            </li>
                                            <li onClick={() => { handleActiveTab('logout') }} className={`my-1 my-md-0 nav-item ${activeTab === 'logout' ? "active" : ""}`}>
                                                <span onClick={() => { doLogout() }} className="nav-link pt-1">
                                                    <i className="fas fa-sign-out-alt" style={{ color: '#bdb6b6' }}></i>
                                                </span>
                                            </li>
                                        </> :
                                        <li onClick={() => { handleActiveTab('login') }} className={`my-1 my-md-0 nav-item ${activeTab === 'login' ? "active" : ""}`}>
                                            <Link to="/login" className="nav-link pt-1">Login</Link>
                                        </li>
                                }
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default NavBar;
