import React from 'react';
import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
    return (
        <div className="container flex-shrink-0  text-justify">
            <Helmet>
                <title>Terms And Conditions | ELL Stores</title>
                <meta name="description" content="ELL Stores brings the best designs of footwear to India, with a carefully curated collection for both men and women who appreciate true craftsmanship and timeless style." />
            </Helmet>

            <h3 className="text-black mt-4 text-uppercase text-center">Terms and Conditions</h3>

            <p className="pt-2">Welcome to ELL Stores!</p>
            <p>These terms and conditions outline the rules and regulations for using ELL Stores's Website, located at <a href="https://ellemporia.com/index.html">https://ellemporia.com/index.html</a></p>
            <p>By accessing this Website, we assume you accept these terms and conditions. Do not continue to use ELL Stores if you do not agree to take all of the terms and conditions stated on this page.</p>
            <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client," "You," and "Your" refers to you, the person log on this Website and compliant to the Company's terms and conditions. "The Company," "Ourselves," "We," "Our," and "Us" refers to our Company. "Party," "Parties," or "Us" refers to both the Client and ourselves. Any use of the above terminology or other words in the singular, plural, capitalization, and/or he/she or they are taken as interchangeable and therefore as referring to same.</p>


            <h5 className="text-black mt-4">Cookies</h5>
            <p>We employ the use of cookies. You agreed to use cookies in Agreement with the ELL Stores's Privacy Policy by accessing ELL Stores.</p>
            <p>Most interactive websites use cookies to retrieve the user's details for each visit. Our Website uses cookies to enable the functionality of certain areas to make it easier for people visiting our Website. Some of our affiliate/advertising partners may also use cookies.</p>
            <p>Parts of this Website offer an opportunity for users to post and exchange opinions and information in certain areas of the Website. ELL Stores does not filter, edit, publish or review Comments before their presence on the Website. Comments do not reflect the views and opinions of ELL Stores, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, ELL Stores shall not be liable for the Comments or any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance the Comments on this Website.</p>
            <p>ELL Stores reserves the right to monitor all Comments and remove any Comments that can be considered inappropriate, offensive, or cause a breach of these Terms and Conditions.</p>
            <p>You warrant and represent that:</p>

            <ul className="ms-5">
                <li>You are entitled to post the Comments on our Website and have all necessary licenses and consents to do so;</li>
                <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party;</li>
                <li>The Comments do not contain any defamatory, libellous, offensive, indecent, or otherwise unlawful material, an invasion of privacy.</li>
                <li>The Comments will not solicit or promote business or custom or present commercial activities or unlawful activity.</li>
            </ul>
            <p>As a result of this grant, ELL Stores has a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any forms, formats, or media.</p>

            <h5 className="text-black mt-4">Hyperlinking to our Content</h5>
            <p>The following organizations may link to our Website without prior written approval:</p>
            <ul className="ms-5">
                <li>Government agencies;</li>
                <li>Search engines;</li>
                <li>News organizations;</li>
                <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
                <li>System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
            </ul>

            <p>These organizations may link to our home page, to publications, or other Website information so long as the link:</p>
            <ol className="ms-3">
                <li>Is not in any way deceptive.</li>
                <li>Does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services.</li>
                <li>It fits within the context of the linking party's site.</li>
            </ol>
            <p>We may consider and approve other link requests from the following types of organizations:</p>
            <ul className="ms-5">
                <li>commonly-known consumer and/or business information sources;</li>
                <li>dot.com community sites;</li>
                <li>associations or other groups representing charities;</li>
                <li>online directory distributors;</li>
                <li>internet portals;</li>
                <li>accounting, law, and consulting firms; and</li>
                <li>Educational institutions and trade associations.</li>
            </ul>
            <p>We will approve link requests from these organizations if we decide that:</p>
            <ol className="ms-3">
                <li>The link would not make us look unfavourably to ourselves or our accredited businesses.</li>
                <li>The organization does not have any negative records with us.</li>
                <li>The benefit to us from the visibility of the hyperlink compensates for the absence of ELL Stores.</li>
                <li>The link is in the context of general resource information.</li>
            </ol>
            <p>These organizations may link to our home page so long as the link:</p>
            <ol className="ms-3">
                <li>Is not in any way deceptive.</li>
                <li>Does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services.</li>
                <li>It fits within the context of the linking party's site.</li>
            </ol>
            <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our Website, you must inform us by sending an e-mail to ELL Stores. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>
            <p>Approved organizations may hyperlink to our Website as follows:</p>
            <ul className="ms-5">
                <li>By use of our corporate name; or</li>
                <li>By use of the uniform resource locator being linked to; or</li>
                <li>Any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.</li>
            </ul>
            <p>No use of ELL Stores's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>

            <h5 className="text-black mt-4">iFrames</h5>
            <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>

            <h5 className="text-black mt-4">Content Liability</h5>
            <p>We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all rising claims on your Website. No link(s) should appear on any Website that may be interpreted as libellous, obscene, or criminal, or infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>

            <h5 className="text-black mt-4">Reservation of Rights</h5>
            <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve of removing all links to our Website upon request immediately. We also reserve the right to amend these terms and conditions and its linking policy at any time. You agree to be bound to and follow these linking terms and conditions by continuously linking to our Website.</p>

            <h5 className="text-black mt-4">Removal of links from our Website</h5>
            <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links, but we are not obligated to or so or to respond to you directly.</p>
            <p>We do not ensure that the information on this Website is correct; we do not warrant its completeness or accuracy; nor do we promise to ensure that the Website remains available or that the material on the Website is kept up to date.</p>

            <h5 className="text-black mt-4">Disclaimer</h5>
            <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our Website and the use of this Website. Nothing in this disclaimer will:</p>
            <ul className="ms-5">
                <li>limit or exclude our or your liability for death or personal injury;</li>
                <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                <li>Exclude any of our liabilities that may not be excluded under applicable law.</li>
            </ul>
            <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.</p>
            <p className="mb-5">As long as the Website and the information and services on the Website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
        </div>
    )
}

export default TermsAndConditions;