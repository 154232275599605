import React from "react";
import ReactImageMagnify from "react-image-magnify";

const Magnify = (props) => {
  const { selectedIndex, images } = props;

  const renderLensComponent = () => {
    return null;
  };
  return (
    <>
      <ReactImageMagnify
        style={{ cursor: 'zoom-in'}}
        {...{
          smallImage: {
            alt: "Wristwatch by Ted Baker London",
            isFluidWidth: true,
            src: images[selectedIndex].original
          },
          largeImage: {
            src: images[selectedIndex].original,
            width: 3200,
            height: 2000,
          },
          enlargedImageContainerDimensions:{
            width: '125%',
            height: '140%',
          },
          enlargedImagePortalId: "myPortal",
          shouldUsePositiveSpaceLens: true,
          lensComponent: renderLensComponent,
        }}
      />
    </>
  );
};

export default Magnify;
