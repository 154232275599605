import React from 'react';
import './index.scss'
import { Helmet } from "react-helmet";

const About = () => {
    return (
        <div className="container flex-shrink-0  text-justify">
             <Helmet>
                <title>About Us | ELL Stores</title>
                <meta name="description" content="ELL Stores brings the best designs of footwear to India, with a carefully curated collection for both men and women who appreciate true craftsmanship and timeless style." />
            </Helmet>
            <div className="d-flex justify-content-center mt-4 mb-3">
                <h1 className="about_ell">
                    <span className="span">ABOUT ELL</span></h1>
            </div>
            <hr />
            <p className="desc_line_height">
                "If it's worth doing, it's worth doing well." It’s this statement that guides everything we do. Quality, craftsmanship,
                excellent service, beautiful design – ELL Stores offers only the best to our discerning customers. We are unequivocally
                passionate about fine handloom-quality accessories, and we want to share that passion with our fellow shoe enthusiasts.
            </p>
            <p className="desc_line_height">
                Until now, perhaps you had to look for premium shoes in every famed stores or cities and countries sometimes to find the
                exclusive brands you love. ELL Stores brings the best designs of footwear to India, with a carefully curated collection
                for both men and women who appreciate true craftsmanship and timeless style. Every visit to the showcase of ELL Stores
                is a personal, intimate experience that’s never confusing… and always a pleasure.
            </p>
            <p className="desc_line_height">We carry brand ELL that puts your very best into everything we make. So, we can offer our customers heirloom-quality accessories that last a lifetime.

            </p>
            <p className="desc_line_height">Every brand has a unique story behind it – it’s not just about good-looking shoes or accessories. It’s about heritage,
                commitment and the knowledge that the best possible work has gone into every make, every stitch, every sole, and every
                shining, hand-polished surface.
            </p>
            <div className="text-center founder_name mt-4 mb-2">Giri Thumati</div>
            <div className="text-center mb-4">- Founder</div>

        </div>
    );
};

export default About;

