import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { API } from '../../Api/API';
import './index.scss'
import { Helmet } from "react-helmet";

const Contact = (props) => {
    const [userName, setUserName] = useState('');
    const [userNameErr, setUserNameErr] = useState(false);
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false);
    const [validateEmailErr, setValidateEmailErr] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberErr, setPhoneNumberErr] = useState(false);
    const [phNoValidationErr, setPhNoValidationErr] = useState(false);
    const [subjectInfo, setSubjectInfo] = useState('');
    const [subjectErr, setSubjectErr] = useState(false);
    const [message, setMessage] = useState('');
    const [messageErr, setMessageErr] = useState(false);

    const handleUserNameInput = (value) => {
        if (value.length <= 0) {
            setUserNameErr(true);
        } else {
            setUserNameErr(false);
        };
        setUserName(value);
    };
    const handleEmailInput = (value) => {
        if (value.length <= 0) {
            setEmailErr(true);
        } else {
            setEmailErr(false);
            if (value.match(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(?:\.\w{2,3})+$/)) {
                setValidateEmailErr(false);
            } else {
                setValidateEmailErr(true);
            };
        };
        setEmail(value);
    }
    const handlePhNumberInput = (value) => {
        if (value.length <= 0) {
            setPhoneNumberErr(true);
        } else {
            setPhoneNumberErr(false);
        };
        if ((value === '' || value.match(/^[0-9\b]+$/)) && value.length <= 10) {
            setPhoneNumber(value);
            if (value.length < 10) {
                setPhNoValidationErr(true);
            } else {
                setPhNoValidationErr(false);
            };
        };
    };
    const handleSubjectInput = (value) => {
        if (value.length <= 0) {
            setSubjectErr(true);
        } else {
            setSubjectErr(false);
        };
        setSubjectInfo(value);
    }
    const handleMessageInput = (value) => {
        if (value.length <= 0) {
            setMessageErr(true);
        } else {
            setMessageErr(false);
        };
        setMessage(value);
    };

    const contactUsApi = async () => {
        if (userName && email && phoneNumber && message && subjectInfo &&
            !userNameErr && !emailErr && !validateEmailErr && !messageErr && !subjectErr &&
            !phoneNumberErr && !phNoValidationErr) {
            let result = await API.contactUs(userName, email, phoneNumber, subjectInfo, message);
            if (result && result.success) {
                let { data } = result;
                if (data) {
                    setUserName('');
                    setEmail('');
                    setPhoneNumber('');
                    setSubjectInfo('');
                    setMessage('');
                    toast.success(data.message);
                } else {
                    toast.error(data.message);
                };
            } else {
                toast.error(result.message);
            };
        };
    };

    return (
        <div className="container text-justify">
            <Helmet>
                <title>Contact Us | ELL Stores </title>
                <meta name="description" content="Reach us on 9164649124 for any queries or you can also write to at support@ellemporia.com." />
            </Helmet>
            <div className="row justify-content-center mt-4 mb-3">
                <h1 className="contact_ell">CONTACT ELL</h1>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-7 mb-3" >
                    <div className="box-shadow white-bg border mb-3 pe-3 ps-3 h-100">
                        <div className="row mt-4">
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <div className="position-relative">
                                        <input id="form_name" type="text" name="name" className="form-control" placeholder="User name"
                                            value={userName}
                                            onBlur={(e) => handleUserNameInput(e.target.value)}
                                            onChange={(e) => handleUserNameInput(e.target.value)}
                                        />
                                    </div>
                                    {
                                        userNameErr ?
                                            <div className="text-end text-danger p-1 err_txt">User name is required.</div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <div className="position-relative">
                                        <input id="form_email" type="text" name="email" className="form-control" placeholder="Email"
                                            value={email}
                                            onBlur={(e) => handleEmailInput(e.target.value)}
                                            onChange={(e) => handleEmailInput(e.target.value)}
                                        />
                                    </div>
                                    {
                                        emailErr ?
                                            <div className="text-end text-danger p-1 err_txt">Email is required</div>
                                            : null
                                    }
                                    {
                                        validateEmailErr && !emailErr ?
                                            <div className="text-end text-danger p-1 err_txt">Please enter correct email</div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 ">
                                <div className="form-group">
                                    <div className="position-relative">
                                        <input id="phoneNumber" type="text" name="phoneNumber" className="form-control" placeholder="Phone number"
                                            value={phoneNumber}
                                            onBlur={(e) => handlePhNumberInput(e.target.value)}
                                            onChange={(e) => handlePhNumberInput(e.target.value)}
                                        />
                                    </div>
                                    {
                                        phoneNumberErr ?
                                            <div className="text-end text-danger p-1 err_txt">Phone number is required.</div>
                                            : null
                                    }
                                    {
                                        phNoValidationErr && !phoneNumberErr ?
                                            <div className="text-end text-danger p-1 err_txt">Invalid mobile number.</div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <div className="position-relative">
                                        <input id="form_email" type="text" name="subjectInfo" className="form-control" placeholder="Subject"
                                            value={subjectInfo}
                                            onBlur={(e) => handleSubjectInput(e.target.value)}
                                            onChange={(e) => handleSubjectInput(e.target.value)}
                                        />
                                    </div>
                                    {
                                        subjectErr ?
                                            <div className="text-end text-danger p-1 err_txt">Subject is required.</div>
                                            : null
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div>
                                <textarea id="form_message" name="message" className="form-control" placeholder="Message" style={{ height: '150px', resize: 'none' }} rows="10"
                                    value={message}
                                    onBlur={(e) => handleMessageInput(e.target.value)}
                                    onChange={(e) => handleMessageInput(e.target.value)}
                                ></textarea>
                                {
                                    messageErr ?
                                        <div className="text-end text-danger p-1 err_txt">Please leave us a message.</div>
                                        : null
                                }
                            </div>
                        </div>
                        <div>
                            <button className="btn btn-border btn-radius mb-md-5" onClick={() => { contactUsApi() }} style={{ textTransform: 'uppercase' }}>Submit</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 mb-3">
                    <div className="box-shadow white-bg border h-100">
                        <h2 className="text-center title d-block text-theme my-3"><span className="span">ELL STORES </span></h2>
                        <div className="text-black form-info white-bg text-center">
                            <ul className="contact-info list-inline d-flex flex-md-row flex-column justify-content-between align-items-between">
                                <li><h6 className="contact_subHeader">Telangana</h6>
                                    <p>7th Floor, Melange Towers, Madhapur, Hyderabad- 500081.</p>
                                    <span className="contact-icon"></span>
                                </li>
                                <li><h6 className="contact_subHeader">Karnataka</h6>
                                    <p>102, Bhuvaneswari Nagar, Anekal Road, Attibele, Bangalore - 562107.</p>
                                    <span className="contact-icon"></span>
                                </li>
                            </ul>
                        </div>
                        <div className="text-black form-info white-bg text-center">
                            <ul className="contact-info list-inline d-flex flex-md-row flex-column justify-content-between align-items-between">
                                <li><h6 className="contact_subHeader">Tamil Nadu</h6>
                                    <p>1-2, Imran Nagar 2nd Street, Bangishop, Ambur - 635802.</p>
                                    <span className="contact-icon"></span>
                                </li>

                                <li><h6 className="contact_subHeader">Contact Us</h6>
                                    <i className="fa fa-phone-square"></i>
                                    <span style={{ fontFamily: 'FontAwesome' }}>{" +91-9164 64 9124"}</span><br />
                                    <i className="fa fa-envelope"></i>
                                    <span style={{ fontFamily: 'FontAwesome' }}>{" support@ellemporia.com"}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Contact;
