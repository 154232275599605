import React, { useEffect, useState } from 'react';
import './index.scss';
import { clearLocalStorage, getItem, getSessionStorage, renderPrice } from '../../Utils/util';
import { API } from '../../Api/API';
import { toast } from 'react-toastify';
import { BaseURL } from '../../Api/NetworkConstants';
import { useHistory } from 'react-router-dom';
import flipcart_icon from '../../Assets/Images/flipkart.svg'
import amazon_icon from '../../Assets/Images/amazon.svg'
import Loader from '../../Components/Loader';
import { Helmet } from "react-helmet";

const Wishlist = (props) => {
    const { getData } = props;
    const history = useHistory();
    const [wishListProducts, setWishListProducts] = useState([]);
    const [hideBtn, setHideBtn] = useState(false);
    const [btnIndex, setBtnIndex] = useState(null);
    const [loading, setLoading] = useState(true);

    const hideBuyNowBtn = (index) => {
        setHideBtn(true);
        setBtnIndex(index);
    }

    const wishListApi = async (accessToken, userInfo) => {
        setLoading(true);
        let userData = JSON.parse(userInfo);
        let result = await API.getWishListProducts(userData.id, accessToken);
        if (result.message === "Invalid token") {
            clearLocalStorage();
            getData();
            history.push('./login');
        }
        if (result && result.success) {
            let { data } = result;
            if (data && data != null) {
                setWishListProducts(data);
            } else {
                toast.error('Something went wrong, please try again');
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        let getAccessToken = getItem('accessToken') || getSessionStorage('accessToken');
        let getUserInfo = getItem('userInfo') || getSessionStorage('userInfo')
        if (getAccessToken) {
            wishListApi(getAccessToken, getUserInfo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderImage = (images, productItem) => {
        let imagesArray = JSON.parse(images);
        return (
            <img
                src={BaseURL + imagesArray[0].thumbnail}
                alt="dummy-img"
                className="wishlist-thumb-img"
                onClick={() => history.push({ pathname: '/product-details', state: { productItem: productItem } })}
            />
        )
    }

    const showBuyNowBtn = () => {
        setBtnIndex(null);
    }

    const renderEcommerce = (item) => {
        let buyAt = JSON.parse(item.buy_at)
        return (
            <div className="mb-1">
                <a className="me-3 my-1 pe_cursor" href={buyAt.amazon} target="_blank" rel="noreferrer">
                    <img src={amazon_icon} className="w-25" alt="amazon" />
                </a>
                <a className="ms-3 my-1 pe_cursor" href={buyAt.flipkart} target="_blank" rel="noreferrer">
                    <img src={flipcart_icon} className="w-25" alt="flipcart" />
                </a>
            </div>
        )

    }

    if (loading) {
        return <Loader />
    } else {
        return (
            <div className="container">
                <Helmet>
                    <title>Wishlist | ELL Stores </title>
                </Helmet>
                <div className="row mt-4 text-center">
                    <h1 className="page_title mb-0">WISH LIST</h1>
                    {wishListProducts && wishListProducts.length > 0 ?
                        <small>( {wishListProducts.length} items )</small> :
                        null
                    }
                </div>
                <hr />
                {wishListProducts.length > 0 ? wishListProducts.map((item, index) => (
                    <div key={index} className="row wishlist_border align-items-center p-2 mb-2">
                        <div className="col-md-4 text-center">
                            {renderImage(item.media, item)}
                        </div>
                        <div className="col-md-3 text-sm-center">
                            <h5 className="my-4" style={{ color: '#221b1b' }}>&#8377; {renderPrice(item.price)} <span className="price_del">&#8377; {renderPrice(item.initial_price)}</span></h5>
                        </div>
                        <div className="col-md-3 text-center">
                            {item.name}
                        </div>
                        <div className="col-md-2 text-center mt-sm-3 mb-sm-3 position-relative">
                            {
                                hideBtn && btnIndex === index ?
                                    <>
                                        <div onClick={() => { showBuyNowBtn() }}
                                            className="position-absolute close_icon pe_cursor">
                                            <i className="fas fa-chevron-left"></i>
                                        </div>
                                        {renderEcommerce(item)}


                                    </>
                                    :
                                    <button onClick={() => { hideBuyNowBtn(index) }} className="btn btn-theme items">buy now</button>
                            }
                        </div>
                    </div>
                ))
                    :
                    <h1 className="text-black text-center">
                        <span>No Products</span>
                    </h1>
                }
                <div className="mb-5"></div>
            </div>
        );
    }
}

export default Wishlist;
