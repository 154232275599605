import React, { Component } from 'react';
// import carousel_firstImg from '../Assets/Images/01.jpg'
import carousel_secondImg from '../Assets/Images/02.jpg'
import accessories_02 from '../Assets/Images/accessories_02.png'
import shoe from '../Assets/Images/shoe.jpg'
import bag from '../Assets/Images/bag.jpg'

import './index.scss'
export default class CarouselComponent extends Component {
    render() {
        return (
            <div id="carouselExampleFade" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="5000">
                        <img src={carousel_secondImg} className="d-block w-100 h-auto" alt="carousel_FirstImg" />
                    </div>
                    {/* <div className="carousel-item" data-bs-interval="5000">
                        <img src={carousel_secondImg} className="d-block w-100 h-auto" alt="carousel_secondImg" />
                    </div> */}
                    <div className="carousel-item" data-bs-interval="5000">
                        <img src={accessories_02} className="d-block w-100 h-auto" alt="carousel_thirdImg" />
                    </div>
                    <div className="carousel-item" data-bs-interval="5000">
                        <img src={shoe} className="d-block w-100 h-auto" alt="carousel_fourthImg" />
                    </div>
                    <div className="carousel-item" data-bs-interval="5000">
                        <img src={bag} className="d-block w-100 h-auto" alt="carousel_fifthImg" />
                    </div>
                </div>
                <button className="carousel-control-prev"
                    aria-label="carousel-control-prev"

                    type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                    <i className="fas fa-angle-left fa-2x" aria-hidden="true"></i>
                </button>
                <button className="carousel-control-next"
                    aria-label="carousel-control-next"
                    type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                    <i className="fas fa-angle-right fa-2x" aria-hidden="true"></i>
                </button>
            </div>
        );
    };
};
