import React, { useState } from 'react';
import './index.scss'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { API } from '../../Api/API';
import { useHistory } from 'react-router-dom';
toast.configure();

const SignUp = () => {
    const history = useHistory();
    const [userName, setUserName] = useState('');
    const [userNameErr, setUserNameErr] = useState(false);
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false);
    const [validateEmailErr, setValidateEmailErr] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordErr, setPasswordErr] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [cnfPassword, setCnfPassword] = useState('');
    const [showCnfPassword, setShowCnfPassword] = useState(false);
    const [cnfPasswordErr, setCnfPasswordErr] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberErr, setPhoneNumberErr] = useState(false);
    const [phNoValidationErr, setPhNoValidationErr] = useState(false);
    const [passwordMatchErr, setPasswordMatchErr] = useState(false);
    const [passwordLengthErr, setPasswordLengthErr] = useState(false);
    const [cnfPasswordLengthErr, setCnfPasswordLengthErr] = useState(false);

    const handleUserNameInput = (value) => {
        if (value.length <= 0) {
            setUserNameErr(true);
        } else {
            setUserNameErr(false);
        };
        setUserName(value);
    }
    const handleEmailInput = (value) => {
        if (value.length <= 0) {
            setEmailErr(true);
        } else {
            setEmailErr(false);
            if (value.match(/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(?:\.\w{2,3})+$/)) {
                setValidateEmailErr(false);
            } else {
                setValidateEmailErr(true);
            };
        };
        setEmail(value);
    }
    const handlePasswordInput = (value) => {
        if (value.length <= 0) {
            setPasswordErr(true);
        } else {
            if (value.length < 6) {
                setPasswordLengthErr(true);
            } else {
                setPasswordLengthErr(false);
            };
            setPasswordErr(false);
        };
        setPassword(value);
    }
    const handleCnfPasswordInput = (value) => {
        if (value.length <= 0) {
            setCnfPasswordErr(true);
        } else {
            if (value.length < 6) {
                setCnfPasswordLengthErr(true);
            } else {
                setCnfPasswordLengthErr(false);
            }
            setCnfPasswordErr(false)
            if (value === password) {
                setPasswordMatchErr(false);
            } else {
                setPasswordMatchErr(true);
            }
        }
        setCnfPassword(value);

    }
    const showHidePassword = () => {
        setShowPassword(!showPassword);
    };
    const showHideCnfPassword = () => {
        setShowCnfPassword(!showCnfPassword);
    };

    const handlePhNumberInput = (value) => {
        if (value.length <= 0) {
            setPhoneNumberErr(true);
        } else {
            setPhoneNumberErr(false);
        };
        if ((value === '' || value.match(/^[0-9\b]+$/)) && value.length <= 10) {
            setPhoneNumber(value);
            if (value.length < 10) {
                setPhNoValidationErr(true);
            } else {
                setPhNoValidationErr(false);
            };
        };
    }

    const signUpApiCall = async () => {
        let result = await API.signup(userName, email, cnfPassword, phoneNumber);
        if (result && result.success) {
            let { data } = result;
            if (data.status) {
                toast.success(data.message);
            } else {
                toast.error(data.message);
            };
            history.push('/login');
        } else {
            toast.error(result.message);
        };

    };
    const doSignUP = (e) => {
        e.preventDefault();
        if (userName && email && password && cnfPassword && phoneNumber &&
            !userNameErr && !emailErr && !validateEmailErr && !passwordErr &&
            !phoneNumberErr && !phNoValidationErr && !passwordMatchErr) {
            signUpApiCall();
        } else {
            toast.error('Enter correct information');
        };
    };

    return (
        <div className="container">
             <Helmet>
                <title>Sign Up | ELL Stores </title>
            </Helmet>
            <div className="row mt-md-5">
                <div className="col-lg-6 col-md-10 ms-auto me-auto">
                    <div className="login-form text-center box-shadow white-bg pt-5 pb-5">
                        <h2 className="title mb-4">
                            <span>SignUp</span>
                        </h2>
                        <form id="contact-form" className="px-md-5 px-4">
                            <div className="form-group">
                                <div className="position-relative">
                                    <i className="fa fa-user"></i>
                                    <input id="form_name" type="text" name="name" className="form-control" placeholder="User name"
                                        value={userName}
                                        onBlur={(e) => handleUserNameInput(e.target.value)}
                                        onChange={(e) => handleUserNameInput(e.target.value)}
                                    />
                                </div>
                                {
                                    userNameErr ?
                                        <div className="text-end text-danger p-1 err_txt">User name is required.</div>
                                        : null
                                }
                            </div>
                            <div className="form-group">
                                <div className="position-relative">
                                    <i className="fas fa-envelope"></i>
                                    <input id="form_email" type="text" name="email" className="form-control" placeholder="Email"
                                        value={email}
                                        onBlur={(e) => handleEmailInput(e.target.value)}
                                        onChange={(e) => handleEmailInput(e.target.value)}
                                    />
                                </div>
                                {
                                    emailErr ?
                                        <div className="text-end text-danger p-1 err_txt">Email is required.</div>
                                        : null
                                }
                                {
                                    validateEmailErr && !emailErr ?
                                        <div className="text-end text-danger p-1 err_txt">Invalid email.</div>
                                        : null
                                }

                            </div>
                            <div className="form-group">
                                <div className="position-relative">
                                    {
                                        showPassword ?
                                            <i onClick={() => { showHidePassword() }} className="fa fa-unlock-alt"></i>
                                            :
                                            <i onClick={() => { showHidePassword() }} className="fas fa-lock"></i>
                                    }
                                    <input id="form_password" type={showPassword ? "text" : "password"} name="password" className="form-control" placeholder="Password"
                                        value={password}
                                        onBlur={(e) => handlePasswordInput(e.target.value)}
                                        onChange={(e) => handlePasswordInput(e.target.value)}
                                    />
                                </div>
                                {
                                    passwordErr ?
                                        <div className="text-end text-danger p-1 err_txt err_txt">Password is required.</div>
                                        : null
                                }
                                {
                                    passwordLengthErr && !passwordErr ?
                                        <div className="text-end text-danger p-1 err_txt err_txt">Enter minimum 6 characters.</div>
                                        : null
                                }
                            </div>
                            <div className="form-group">
                                <div className="position-relative">
                                    {
                                        showCnfPassword ?
                                            <i onClick={() => { showHideCnfPassword() }} className="fa fa-unlock-alt"></i>
                                            :
                                            <i onClick={() => { showHideCnfPassword() }} className="fas fa-lock"></i>
                                    }
                                    <input id="form_cnfPassword" type={showCnfPassword ? "text" : "password"} name="password" className="form-control" placeholder="Confirm password"
                                        value={cnfPassword}
                                        onBlur={(e) => handleCnfPasswordInput(e.target.value)}
                                        onChange={(e) => handleCnfPasswordInput(e.target.value)}
                                    />
                                </div>
                                {
                                    cnfPasswordErr ?
                                        <div className="text-end text-danger p-1 err_txt">Confirm password is required.</div>
                                        : null
                                }
                                {
                                    cnfPasswordLengthErr && !cnfPasswordErr ?
                                        <div className="text-end text-danger p-1 err_txt">Enter minimum 6 characters.</div>
                                        : null
                                }
                                {
                                    passwordMatchErr && !cnfPasswordErr && !cnfPasswordLengthErr ?
                                        <div className="text-end text-danger p-1 err_txt">Passwords not matching.</div>
                                        : null
                                }
                            </div>
                            <div className="form-group">
                                <div className="position-relative">
                                    <i className="fas fa-phone"></i>
                                    <input id="phoneNumber" type="text" name="phoneNumber" className="form-control" placeholder="Phone number"
                                        value={phoneNumber}
                                        onBlur={(e) => handlePhNumberInput(e.target.value)}
                                        onChange={(e) => handlePhNumberInput(e.target.value)}
                                    />
                                </div>
                                {
                                    phoneNumberErr ?
                                        <div className="text-end text-danger p-1 err_txt">Phone number is required.</div>
                                        : null
                                }
                                {
                                    phNoValidationErr && !phoneNumberErr ?
                                        <div className="text-end text-danger p-1 err_txt">Invalid mobile number.</div>
                                        : null
                                }
                            </div>
                            <button onClick={(e) => { doSignUP(e) }} style={{ width: '100%' }} className="btn btn-theme btn-block">
                                <span>Signup</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
