import React, { useEffect, useState } from 'react';
import { API } from '../../Api/API';
import { toast } from 'react-toastify';
import Product from '../../Components/Product';
import './index.scss'
import { clearLocalStorage } from '../../Utils/util';
import { useHistory } from 'react-router-dom';
import Loader from '../../Components/Loader';
import { Helmet } from "react-helmet";

const Shop = (props) => {
    const { getData, role } = props;
    const history = useHistory();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    const getAllProductsApi = async () => {
        let result = await API.getAllProducts();
        if (result.message === "Invalid token") {
            clearLocalStorage();
            getData();
            history.push('./login');
        }
        if (result && result.success) {
            let { data } = result;
            setProducts(data);
        } else {
            toast.error(result.message);
        };
        setLoading(false);
    }

    useEffect(() => {
        getAllProductsApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])
    if (loading) {
        return <Loader />;
    } else {
        return (
            <div className="container">
                <Helmet>
                    <title>Shop Top Quality Men’s Shoes Online | ELL Stores</title>
                    <meta name="description" content="Check out the latest collections from a wide range of men's shoes with top quality, thoughtfully designed, and crafted. Buy online at the best prices." />
                </Helmet>
                <div className="d-flex justify-content-center mt-4 mb-3">
                    <h1 className="page_title">ELL SHOP</h1>
                </div>
                <hr />
                <p className="desc_line_height  text-justify">
                    Discover the new trends, styles, new collections, and fashion and lifestyle inspiration from the world
                    of ELL Stores. Our collection of genuine leather shoes and accessories illuminate your style for
                    every occasion, from casual looks to your office attire. Each of our Shoes, Wallets, Jackets,
                    Handbags, and Belts are made using signature quality and design. We&#39;re sure these will be the first
                    pair of shoes that somehow stand out more to you than the other shoes on the rack. While you&#39;re
                    here, why not check out our range of stylish men&#39;s casual boots.
                </p>
                <div className="row mt-md-2">
                    {
                        products && products.map((item, index) => (
                            <Product key={index} productItem={item} index={index} role={role} />
                        ))
                    }
                </div>
            </div>
        );
    };
};

export default Shop;
