import React from 'react';
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
    return (
        <div className="container flex-shrink-0  text-justify">
            <Helmet>
                <title>Privacy Policy | ELL Stores</title>
                <meta name="description" content="ELL Stores brings the best designs of footwear to India, with a carefully curated collection for both men and women who appreciate true craftsmanship and timeless style." />
            </Helmet>

            <h3 className="text-black m-4 text-uppercase text-center">Privacy Policy</h3>
            <p>If you supply us with your postal address or email address, you will only receive the information you provided us with your postal address or email address.</p>
            
            <p>From time to time, we may use customer information for new, unanticipated uses not previously disclosed in our privacy notice. ELL Stores only collects Personal Information that you submit voluntarily when you provide your name, address, and any other contact information you choose to provide on the "Contact," "Careers," "Print Disclaimer," "Logo Specs," "Postcard Specs," "Newsletter Subscribe" and "Blog" portion of the Site. Such Personal Information is collected for the sole purpose of adding your name and contact information to ELL Stores and a limited mailing list to provide you with publications, promotions, updates, and invitations to upcoming ELL Stores events that may be of interest to you. By providing ELL Stores. Limited with Personal Information on these portions of the Site, you consent to collecting and using such information following this privacy statement ELL Stores. Limited does not disclose any Personal Information to any third parties, except as required by law. If you are concerned about how your information is used, you should check back at our website periodically.</p>
           
            <p>Would you please let us know of any questions or concerns via email at <a href="/">info@ellemporia.com</a>? Thank you for visiting www.ellemporia.com</p>
        </div>
    )
}

export default PrivacyPolicy;