import React from 'react';
import { Helmet } from "react-helmet";

const ExternalLinks = () => {
    return (
        <div className="container flex-shrink-0  text-justify">
            <Helmet>
                <title>External Links | ELL Stores</title>
                <meta name="description" content="ELL Stores brings the best designs of footwear to India, with a carefully curated collection for both men and women who appreciate true craftsmanship and timeless style." />
            </Helmet>

            {/* <h3 className="text-black m-4 text-uppercase text-center">ELL Stores’s Third-Party Links Disclaimer</h3> */}
            <p className="my-4">ELL Stores’s Third-Party Links Disclaimer</p>
            <p>Third-party links on our website are provided for visitors’ convenience only. ELL Stores accepts no liability for any content on the linked sites or the site itself, and links taken to other sites are done at your own risk. ELL Stores does its utmost to link to reputable websites but ultimately has no control over this content.</p>
            <p>When using the third-party links on the ELL Stores website, please remember you should review the privacy policies of those websites and the terms and conditions to get a better understanding of what, why, and how they collect and use any personally identifiable information.</p>
            <p>Hyperlinks and hypertext are provided for your ease so that you can access the variety of information and services provided. We accept no liability arising out of the use of such links, including:</p>

            <ul>
                <li>misuse or misunderstanding of these hyperlinks and hypertext links and website navigational methods</li>
                <li>third party interpretation of data or information which is distributed around the website and reached using hypertext and hyperlinks</li>
                <li>third party understanding of or use of the navigational structure of the site or the interpretation of distributed information on the site</li>
            </ul>
            <p>We may revise this disclaimer at any time, without prior notice, by updating this web page.</p>

        </div>
    )
}

export default ExternalLinks;