/* eslint-disable array-callback-return */
import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Magnify from "./Magnify";
import { BaseURL } from "../Api/NetworkConstants";

class ProductGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      selectedIndex: 0,
    };
    this.handleClickImage = this.handleClickImage.bind(this);
  };

  renderMainImage() {
    return (
      <Magnify
        selectedIndex={this.state.selectedIndex}
        images={this.state.images}
      />
    );
  };

  handleClickImage(event, index) {
    this.setState({
      selectedIndex: index
    });
  };

  renderPrevImage() {
    if (this.state.selectedIndex <= 0) {
      this.setState({
        selectedIndex: this.state.images.length - 1
      })
    } else {
      this.setState({
        selectedIndex: this.state.selectedIndex - 1
      })
    }
  }

  SamplePrevArrow(onClick) {
    return (
      <button
        className="image-gallery-icon image-gallery-left-nav"
        aria-label="Previous Slide"
      >
        <i className="fas fa-angle-left fa-2x" onClick={() => { onClick(); this.renderPrevImage() }} aria-hidden="true"></i>
      </button>
    );
  }



  renderNextImage() {
    let nextIndex = this.state.selectedIndex + 1;
    let newIndex = nextIndex % this.state.images.length
    this.setState({
      selectedIndex: newIndex
    })
  }

  SampleNextArrow(onClick) {
    return (
      <button type="button"
        className="image-gallery-icon image-gallery-right-nav"
        aria-label="Next Slide"
      >
        <i className="fas fa-angle-right fa-2x" onClick={() => { onClick(); this.renderNextImage() }} aria-hidden="true"></i>
      </button>
    );
  }

  properties = () => {
    return {
      useTranslate3D: false,
      thumbnailPosition: "bottom",
      useBrowserFullscreen: false,
      showFullscreenButton: false,
      showPlayButton: false,
      renderItem: this.renderMainImage.bind(this),
      items: this.state.images,
      infiniteSliding: true,
      onThumbnailClick: (event, index) => this.handleClickImage(event, index),
      renderLeftNav: (onClick) => this.SamplePrevArrow(onClick),
      renderRightNav: (onClick) => this.SampleNextArrow(onClick),
    };
  };
  componentDidMount() {
    let final = this.props.images.map((obj) => {
      return {
        original: BaseURL + obj.original,
        thumbnail: BaseURL + obj.thumbnail,
      };
    });
    this.setState(
      {
        images: final,
      }
    );
  };
  render() {
    console.log('this,============>', this.state.images)
    return (
      <>
        <ImageGallery {...this.properties()} />
      </>
    );
  };
};

export default ProductGallery;
